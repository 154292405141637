var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "public-page"
  }, [_c('img', {
    staticClass: "public-page__background",
    attrs: {
      "src": require(`@/assets/images/index_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "770",
      "viewBox": "0 0 1440 770",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter0_f_1201:7621)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M588.078 227.574C588.078 227.574 622.205 307.177 612.14 336.215C602.076 365.253 309.789 448.779 286.111 440.573C262.434 432.366 261.838 361.584 271.902 332.546C281.966 303.508 588.078 227.574 588.078 227.574Z",
      "fill": "#A09EFF",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter1_f_1201:7621)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1413.08 -93.4255C1413.08 -93.4255 1447.2 -13.823 1437.14 15.2152C1427.08 44.2533 1134.79 127.779 1111.11 119.573C1087.43 111.366 1086.84 40.584 1096.9 11.5459C1106.97 -17.4923 1413.08 -93.4255 1413.08 -93.4255Z",
      "fill": "#FFECF3",
      "fill-opacity": "0.85"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1201:7621",
      "x": "-62.273",
      "y": "-100.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1201:7621"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1201:7621",
      "x": "762.727",
      "y": "-421.426",
      "width": "1004.27",
      "height": "869.562",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1201:7621"
    }
  })], 1)])])]), _c('public-header'), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "card"
  }, [_vm._t("default")], 2)]), _c('public-footer')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };